<template>
  <div>
 <section>
      <div class="text-center p-lg-5 p-2 mt-0 mr-lg-15 ml-lg-15 mb-0 pb-5">
        <span class="section-title"> Explore Our Community</span>
        <h3 class="section-subtitle pt-2 section4-title">
          Post Your Question Or Poll And Get Answers From Thousands Of Experts
          Worldwide
        </h3>
      </div>
      <div class="mr-lg-15 pl-lg-5 pr-lg-5 ml-lg-15" v-if="answer_question_data" >
        <b-row
          v-for="(items, index) in answer_question_data.slice(0,no_of_question)"
          :key="index"
          @click="makevisible(index)"
          class="lightColor w-100 m-0 mb-5"
        >
          <b-col cols="12" lg="12" class="pb-0 pb-lg-2 pb-sm-2" sm="12">
            <b-avatar icon="question-diamond-fill" size="md"></b-avatar>

            <span class="fs-sm-6 fw-bolder" >{{ items.question }}</span>
            <span class="p-lg-2 p-1 font-13 pt-2 float-end">{{
              items.created_at | formatDate
            }}</span>
          </b-col>

          <div v-if="index == vid" class="">
            <b-collapse
              id="collapse-4"
              v-model="visible"
              class="mt-2 mb-2 bg-transparent"
              v-for="(aitem, aindex) in items.answer_question"
              :key="aindex"
            >
              <b-card class="text-2">{{ aitem.answer }}</b-card>
            </b-collapse>
          </div>
        </b-row>
      </div>
      <div class="text-center mt-5 mb-5" v-if="no_of_question<answer_question_data.length">
     <!-- <b-button class="button-bg-dark border-radius-8 font-size-20"
             v-on:click="no_of_question+=5" >  -->
             <v-btn 
                    depressed
                    elevation="6"
                    icon
                    outlined
                    raised
                   v-on:click="no_of_question+=5"
                    color="black"
                  >
                    <v-icon>mdi-plus</v-icon></v-btn
                  >
                  <!-- </b-button
            > -->
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  

  data: function () {
    return {
    answer_question_data: [],
       vid: null,
      visible: false,
      no_of_question:5
    };
  },

  created() {
    this.getdata();
  },

  methods: {
    ...mapActions(["getQuestionAnswerList"]),
  getdata()
  {
      
      this.getQuestionAnswerList({page:"detail"}).then((response) => {
     
        this.answer_question_data=response.data;
       
       // console.log(this.answer_question_data.length)
      });
  },
    makevisible(id) {
      this.vid = id;
      this.visible = !this.visible;
    },
   
    
   
  },
};
</script>
